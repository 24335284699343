import { createI18n } from "vue-i18n";
import enTranslation from "./en.i18n";
import koTranslation from "./ko.i18n";

const messages = {
  en: enTranslation,
  ko: koTranslation,
};

const numberFormats = {
  ko: {
    currency: {
      style: "currency",
      currency: "KRW",
    },
  },
};

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  globalInjection: true,
  messages,
  numberFormats,
});

export default i18n;
