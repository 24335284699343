import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/room/:roomno",
    name: "DealerRoom",
    component: () =>
      import(/* webpackChunkName: "room" */ "../views/DealerRoom.vue"),
  },
  {
    path: "/invalid",
    name: "Invalid",
    component: () =>
      import(/* webpackChunkName: "room" */ "../views/errors/Invalid.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
