const messages = {
  PlayerWin: "PLAYER WIN",
  BankerWin: "BANKER WIN",
  BankerPairWin: "Banker Pair WIN",
  PlayerPairWin: "Player Pair WIN",
  TigerWin: "TIGER WIN",
  DragonWin: "DRAGON WIN",
  Tie: "TIE",
  GameCancelled: "GAME CANCELLED",
  Start: "START",
  Complete: "COMPLETE",
  Shuffle: "SHUFFLE",
  Cancel: "CANCEL",
  Player: "PLAYER",
  Banker: "BANKER",
  Dragon: "DRAGON",
  Tiger: "TIGER",
  betStatus: {
    ready: "READY",
    bet: "BETTING",
    nomore: "NO MORE BET",
    stop: "STOP - DEAL CARD",
    complete: "COMPLETE - CLICK START BUTTON",
    shuffling: "SHUFFLING",
    cancelled: "CANCEL - CLICK START BUTTON",
  },
};

export default messages;
